/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";

import {
  RasmusUserRegistration,
  RasmusUserRegistrationConfig,
} from "@/features/rasmus-kids-club/users/registration";

export const initRasmusUserRegistration = () => {
  const el = document.getElementById("rasmus-user-registration");

  if (!el) {
    return;
  }

  try {
    const dataSettings = el.getAttribute("data-settings");

    if (!dataSettings) {
      return;
    }

    const parsedSettings: RasmusUserRegistrationConfig = JSON.parse(
      dataSettings
    );

    new Vue({
      el,
      render: (h) =>
        h(RasmusUserRegistration, {
          props: {
            settings: parsedSettings,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "RasmusUserRegistration"');
  }
};

initRasmusUserRegistration();
