












import { defineComponent, PropType, reactive, ref } from "@vue/composition-api";
import {
  RasmusUserForm,
  RasmusUserParams,
  RasmusUserKidParams,
  ApiErrorType,
} from "@/features/rasmus-kids-club/shared";

import {
  rasmusRequest,
  apiDateFormat,
  redirectToPath,
} from "@/features/rasmus-kids-club/shared/helpers";

import {
  RasmusUserRegistrationApiData,
  RasmusUserRegistrationSettings,
} from ".";

export default defineComponent({
  name: "RasmusUserRegistration",
  components: {
    RasmusUserForm,
  },
  props: {
    settings: {
      type: Object as PropType<RasmusUserRegistrationSettings>,
      default: undefined,
    },
  },
  setup(props) {
    const formData: RasmusUserParams | undefined = undefined;
    const settings: RasmusUserRegistrationSettings = props.settings;
    const newsletterLink = settings.newsletterLink;
    const privacyLink = settings.privacyLink;

    let errors: ApiErrorType = reactive({
      messages: undefined,
    });

    let submitting = ref(false);

    const apiFormatKids = (kids: RasmusUserKidParams[]) =>
      kids?.map((kid) => ({
        gender: kid.gender,
        birthday: apiDateFormat(kid.birthday),
        firstName: kid.firstName,
        lastName: kid.lastName,
      }));

    const generateApiData = (
      formData: RasmusUserParams
    ): RasmusUserRegistrationApiData => {
      return {
        action: "ADD",
        data: {
          email: formData?.email,
          address: formData?.address,
          streetNumber: formData?.streetNumber,
          city: formData?.city,
          zip: formData?.zip,
          terms: formData?.terms,
          password: formData?.password,
          newsletter: formData?.newsletter,
          customerNumber: formData?.customerNumber,
          firstNameParentOne: formData?.firstNameParentOne,
          lastNameParentOne: formData?.lastNameParentOne,
          firstNameParentTwo: formData?.firstNameParentTwo,
          lastNameParentTwo: formData?.lastNameParentTwo,
          group: {
            id: settings?.userGroup,
          },
          kids: apiFormatKids(formData.kids),
        },
      };
    };

    const submitApiData = async (formData: RasmusUserParams) => {
      submitting.value = true;
      const apiData = generateApiData(formData);
      const response = await rasmusRequest<RasmusUserRegistrationApiData>(
        apiData
      );
      submitting.value = false;

      if (response.success) {
        redirectToPath(response.data?.redirectUrl);
      } else {
        errors.messages = response.message;
      }
    };

    return {
      data: props.settings,
      formData,
      errors,
      submitting,
      newsletterLink,
      privacyLink,
      submitApiData,
    };
  },
});
